(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .factory('AuthServerProvider', AuthServerProvider);

    AuthServerProvider.$inject = ['API_URL','$http', '$localStorage', 'Base64' ];

    function AuthServerProvider (API_URL, $http, $localStorage, Base64) {
        var service = {
            getToken: getToken,
            hasValidToken: hasValidToken,
            login: login,
            logout: logout,
            isBlocked: isBlocked
        };

        return service;

        function getToken () {
            var token = $localStorage.authenticationToken;
            return token;
        }

        function hasValidToken () {
            var token = this.getToken();
            return !!token;
        }

        function login (credentials) {
            var base64EncodedString = Base64.encode(credentials.username +':' + credentials.password);
            var authorizationHeader = undefined;
            if(base64EncodedString==null || base64EncodedString == ''){
                authorizationHeader = 'Basic';
            }else{
                authorizationHeader = 'Basic ' + base64EncodedString;
            }
            return $http.post(API_URL + 'api/authenticate', '', {
                headers: {
                    'Authorization': authorizationHeader
                    // 'Content-Type': 'application/x-www-form-urlencoded'
                },
            });
        }

        function isBlocked (email) {
            return $http({
                method: 'GET',
                url: API_URL + 'api/isBlocked?email=' + email + '&gr=DHL'
            });
        }

        function logout () {
            // logout from the server
            $http.post(API_URL + 'api/logout').success(function (response) {
                delete $localStorage.authenticationToken;
                // to get a new csrf token call the api
                // $http.get(API_URL +'api/session');
                return response;
            });

        }
    }
})();
